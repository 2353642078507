* {
  box-sizing: border-box;
}

:root {
  /* --main: #e03636; */
  /* --grey: #abb8c3; */
  /* --light: #F6F9FC; */
  /* --dark: #000; */
  /* --accent-colour: #635BFF; */
  /* --dark-terminal-color: #0A2540; */
  --transition: all 250ms ease;
  --radius: 0.5rem;
  --shadow: drop-shadow(0px 4px 15px rgba(0, 0, 0, 0.3));
}

html {
  font-family: Arial, Helvetica, sans-serif;
  background-color: var(--light);
  font-size: calc(8px + 0.5vw) !important;
  /* scroll-behavior: smooth; */
}

body {
  margin: 0;
  padding: 0;
  line-height: 1.3rem;
}

h3 {
  line-height: 1.6rem;
}

.btn {
  text-decoration: none;
  cursor: pointer;
  outline: none;
  border: none;
  color: var(--light);
  background-color: var(--main);
  padding: 0.5rem;
  border-radius: var(--radius);
  transition: var(--transition);
}

.btn:hover {
  opacity: 75%;
}

.button,
.checkout-form-buttons a {
  text-decoration: none;
  background: var(--dark);
  border-radius: var(--radius);
  color: var(--light);
  border: 0;
  padding: 0.75rem;
  /* margin-top: 16px; */
  font-weight: 600;
  cursor: pointer;
  transition: var(--transition);
  display: block;
}

.button:hover,
.checkout-form-buttons a:hover {
  color: var(--grey);
}

.button:disabled,
.disabled {
  opacity: 0.5;
  cursor: none;
}

.flex {
  display: flex;
  align-items: center;
}

/* loading styling */
.loading-container {
  background-color: #222;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  overflow: hidden;
  height: 100vh;
}

.loading-container i {
  font-size: 5rem;
  color: var(--light);
}

.loading-container h2 {
  color: var(--light);
}

.loading-container strong {
  color: var(--main);
}

/* header styling */
header {
  justify-content: space-around;
  padding: 1.5rem 2rem;
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1000;
}


.cart-header {
  position: static;
  background-color: var(--dark);
}

.logo-container img {
  width: 13vw;
  height: 5vw;
}

.main-nav ul.flex {
  list-style: none;
  margin: 0;
  padding: 0;
  justify-content: center;
  gap: 1rem;
}

.main-nav ul.flex a,
.main-nav ul.flex button {
  cursor: pointer;
  font-weight: 800;
  border: none;
  color: var(--light);
  text-decoration: none;
  text-transform: uppercase;
  position: relative;
  transition: all 250ms ease-in;
}

.main-nav ul.flex a:hover,
.main-nav ul.flex button:hover {
  color: var(--main);
}

#cartIcon {
  position: relative;
  display: block;
  padding: 0.5rem;
  background-color: var(--light);
  color: var(--dark);
  border: none;
  border-radius: var(--radius);
  z-index: 1;
}

#cartIcon::after {
  content: '';
  position: absolute;
  bottom: -0.5rem;
  left: 0;
  width: 100%;
  height: 0.2rem;
  transform: scale(0);
  background-color: var(--main);
  border-radius: inherit;
  transition: var(--transition);
  z-index: -1;
}

#cartIcon:hover::after {
  transform: scale(1);
}

#cartIcon span {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: -0.5rem;
  right: -0.5rem;
  z-index: 10;
  padding: 0.2rem;
  width: 20px;
  height: 20px;
  font-size: 0.9rem;
  border-radius: 50%;
  background-color: var(--main);
  color: var(--light);
}

.active {
  position: relative;
  padding: 0.2rem;
  color: var(--main) !important;
  transition: var(--transition);
}

.active::after {
  content: "";
  position: absolute;
  bottom: -0.1rem;
  left: 0;
  width: 100%;
  height: 0.2rem;
  background-color: var(--main);
}

.languages-container {
  position: absolute;
  top: 0;
  right: 0;
  gap: 1rem;
  padding: 0.5rem;
}

.languages-container button {
  margin: 0;
  font-size: 1rem;
  color: var(--light);
  background-color: transparent;
  border: 0.1rem solid var(--light);
  border-radius: 0.25rem;
  padding: 0.25rem;
  text-transform: uppercase;
  cursor: pointer;
  transition: var(--transition);
}

.languages-container button:hover {
  background-color: var(--main);
}

/* cart page */
.cart-container {
  padding: 2rem 0;
  align-items: start;
}

.cart-container>div {
  flex: 1;
}

.cart-items>div.flex {
  justify-content: space-between;
}

.cart-items>div.flex a {
  text-decoration: none;
  color: var(--light);
  background-color: var(--dark);
  padding: 0.5rem;
  border-radius: var(--radius);
  transition: all 250ms ease-in;
}

.cart-items>div.flex a:hover {
  color: var(--grey);
}

.cart-item {
  align-items: start;
}

.cart-image {
  width: 10vw;
  min-height: 10vw;
}

.cart-image img {
  width: 100%;
  height: 100%;
}

.cart-details {
  width: 100%;
  padding: 0 1rem;
}

.cart-details strong {
  color: var(--main);
}

.cart-details h3 {
  margin: 0;
}

.cart-details div.flex {
  justify-content: space-between;
}

.cart-details button {
  padding: 0.5rem;
}

.cart-buttons-container {
  gap: 0.5rem;
  justify-content: flex-end !important;
}

.cart-button-input input::-webkit-outer-spin-button,
.cart-button-input input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.cart-button-input {
  width: 50%;
}

.cart-button-input input {
  width: 100%;
  color: var(--dark);
  border-radius: 0;
  border-color: var(--dark);
  background-color: var(--light);
  font-size: 1rem;
  padding: 0.5rem 0.2rem;
}

.cart-buttons,
.cart-button-input {
  border-right: solid 0.1rem var(--dark);
  padding-right: 0.5rem;
}

.cart-buttons button {
  width: 18px;
  height: 18px;
  font-weight: 400;
  font-size: 1rem;
  border-radius: 0;
  margin: 0;
  padding: 0;
}

.cart-summary-card {
  width: 70%;
  margin: 0 auto;
  padding: 1rem 2rem;
  border-radius: 1rem;
  filter: var(--shadow);
  background-color: var(--light);
}

/* .cart-summary-card h2 {
  margin: 0;
} */

.cart-summary-card a {
  box-sizing: border-box;
  text-decoration: none;
  color: var(--light);
  background-color: var(--dark);
  padding: 0.75rem;
  border-radius: var(--radius);
  width: 100%;
  display: block;
  text-align: center;
  transition: var(--transition);
}

.cart-summary-card a:hover {
  color: var(--grey);
}

.empty-cart {
  min-height: 30vh;
  flex-direction: column;
  justify-content: space-between;
  align-items: start;
}

.empty-cart>div.flex {
  justify-content: space-between;
}

/* shop page */
.masthead {
  flex-direction: column;
  justify-content: center;
  text-align: center;
  /* position: absolute; */
  /* top: 0; */
  /* left: 0; */
  /* width: 100%; */
  min-height: 100dvh;
  min-height: 100vh;
  /* transform: translateY(-100px); */
}

.masthead-container {
  flex-direction: column;
  gap: 2rem;
  padding: 1rem;
  color: var(--light)
}

.masthead-container img {
  position: absolute;
  inset: 0;
  z-index: -10;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.backdrop {
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.55);
  z-index: -1;
}

.masthead-container h2 {
  margin: 0;
}

.masthead-container h1 {
  margin: 0;
  font-size: 3.5rem;
  color: var(--light);
  text-transform: uppercase;
}

.divider {
  height: 0.8rem;
  background-color: var(--main);
  width: 20%;
  margin: 0 auto;
}

.masthead-buttons {
  gap: 4rem;
}

.masthead-buttons a {
  font-weight: 800;
  position: relative;
  text-decoration: none;
  color: var(--light);
  padding: 0.5rem;
  background-color: rgba(0, 0, 0, 0.5);
  border: 0.1rem solid var(--light);
  border-radius: var(--radius);
  transition: var(--transition);
  z-index: 1;
}

.masthead-buttons a::after {
  content: "";
  position: absolute;
  inset: 0;
  width: 0%;
  height: 100%;
  z-index: -1;
  transform-origin: left;
  border-radius: var(--radius);
  background-color: var(--main);
  transition: var(--transition);
}

.masthead-buttons a:hover::after {
  width: 100%;

}

.content {
  justify-content: space-evenly;
  margin-top: 2rem;
  padding: 2rem 4rem;
}

.content h2 {
  position: relative;
  padding-left: 10px;
}

.content h2::after {
  content: "";
  position: absolute;
  inset: 0;
  width: 5px;
  background-color: var(--main);
}

.welcome-content {
  padding: 1rem;
  margin-bottom: 2.5rem;
  width: 50%;
}

.about-content {
  gap: 5rem;
  justify-content: space-between;
}

.about-content-container {
  line-height: 1.5rem;
  width: 60%;
}

#shop {
  border-top: 0.5rem solid var(--main);
}

#shop>div.flex {
  gap: 3rem;
  padding: 3rem;
  width: 90%;
  margin: 0 auto;
  flex-wrap: wrap;
  align-items: baseline;
  justify-content: center;
}

.filtered {
  min-height: 100vh;
  transition: var(--transition);
}

.card-grid-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  justify-items: center;
  gap: 2.5rem;
  padding: 1rem;
}

.card {
  cursor: pointer;
  text-decoration: none;
  /* width: calc((100% - 0.5rem) / 1.5); */
  width: 100%;
  /* Calculate width with gap included */
  color: var(--dark);
  background-color: var(--light);
  /* flex-basis: 30%; */
  filter: var(--shadow);
  transition: var(--transition);
}

.card:hover {
  transform: scale(1.05);
}

.card-image {
  position: relative;
  width: 100%;
  height: 15rem;
}

.card-image img {
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
  /* object-position: center; */
}

.card-content {
  position: relative;
  /* min-height: 100%; */
  flex-direction: column;
  justify-content: space-between;
  padding: 1rem;
  text-align: center;
  z-index: -1;
}

.card-content h4:nth-of-type(1) {
  margin: 0.4rem;
}

.card-content .flex {
  justify-content: space-between;
}

.card-content a {
  text-decoration: none;
  color: var(--dark);
}

.card-content button {
  cursor: pointer;
  position: absolute;
  right: 0;
  top: 0;
  background-color: transparent;
  outline: none;
  border: none;
  font-size: 1.2rem;
  transition: var(--transition);
  z-index: 1000;
}

.card-content button:hover {
  transform: scale(1.1);
}

.card-content p {
  margin: 0;
  text-transform: uppercase;
  font-weight: 600;
  color: #666;
  font-size: 0.95rem;
}

.categories {
  position: sticky;
  top: 0;
  background-color: var(--light);
  overflow-y: hidden;
  padding-bottom: 1rem;
  padding-top: 0.5rem;
  margin-top: 0;
  z-index: 100;
  transition: var(--transition);
}


.categories-sticky {
  /* padding-top: 0.5rem;
  margin-top: 0;
  z-index: 100; */
  filter: var(--shadow);
}

.categories div.flex {
  gap: 2rem;
  justify-content: center;
}

.categories div.flex a {
  text-align: center;
}

.categories div.categories-array-container {
  justify-content: flex-start;
}

.categories div.categories-array-container h2 {
  cursor: pointer;
}

.categories div.categories-array {
  width: 100%;
}

.categories div.categories-array .btn {
  border-radius: 0.2rem;
  background-color: var(--dark);
}

.categories div.categories-array .btn.selected-category {
  background-color: var(--main);
}

/* .categories div.flex a {
  position: relative;
  text-decoration: none;
  text-align: center;
  padding: 0.5rem;
  background-color: var(--main);
  color: var(--light);
  border-radius: var(--radius);
  transition: var(--transition);
}

.categories div.flex a::after {
  content: "";
  position: absolute;
  z-index: -1;
  bottom: -0.2rem;
  right: -0.2rem;
  background-color: var(--grey);
  border-radius: var(--radius);
  width: 100%;
  height: 100%;
  transform: scale(0);
  transition: var(--transition);
  transition-duration: 300ms;
  transform-origin: top left;
}

.categories div.flex a:hover::after {
  transform: scale(1);
} */

.hide-category {
  width: 0;
  height: 0;
  padding: 0;
}

/* 
.content#shop {
  padding: 0;
} */


.no-matches {
  width: 100%;
  text-align: center;
  grid-column: 1;
}

.no-matches i {
  color: var(--main);
}

.categories i {
  cursor: pointer;
  font-size: 1.1rem;
  transition: var(--transition);
}

/* .search-bar i.fa-magnifying-glass,
.search-bar i.fa-xmark,
.search-bar i.fa-angle-right {
  display: none;
} */
.hide-search {
  width: 0;
  height: 0;
  opacity: 0;
  transition: var(--transition);
}

.search-bar {
  gap: 0 !important;
  justify-content: space-between !important;
  /* transition: all 1s ease; */
  width: 75%;
  margin: 0 auto;
  padding: 0 0.5rem;
  border-radius: var(--radius);
  border: 0.15rem solid var(--grey);
  transition: var(--transition);
}

.search-bar input {
  background-color: var(--light);
  width: 100%;
  height: auto;
  padding: 0.5rem;
  border: none;
}

.open-search {
  width: 100%;
  height: auto;
  padding: 0.5rem;
}

.search-bar:focus {
  border-color: var(--main);
}

.search-bar input:focus {
  outline: none;
  border: none;
}

/* product page */
.product-container {
  padding-top: 0;
  justify-content: center;
  align-items: flex-start;
  gap: 2rem;
  width: 100%;
  min-height: 50vh;
  position: relative;
}

.product-container img {
  filter: var(--shadow);
}

.product-container .product-image {
  width: 30%;
}

.product-container .product-image img {
  width: 100%;
}

.product-content {
  width: 50%;
  align-items: center;
  flex-direction: column;
  align-items: baseline;
  justify-content: space-between;
}

.product-details h3 {
  font-size: 1.5rem;
}

.product-details>p:nth-of-type(1) {
  text-transform: uppercase;
  font-weight: 600;
  color: #666;
  font-size: 0.95rem;
  margin-bottom: 0;
}

.product-container #price {
  font-size: 1.5rem;
  color: var(--main);
}

.product-buttons-container {
  gap: 1rem;
  justify-content: baseline;
}

.product-buttons-container .button {
  font-size: 1rem;
}

.product-buttons-container #cart-btn {
  background-color: var(--main);
}

.product-buttons-container #cart-btn:hover {
  color: var(--light);
  opacity: 75%;
}

.buttons-stock-container {
  justify-content: space-between;
}

.cart-item .stock-container {
  width: max-content;
}

.cart-item .stock-container p {
  margin-bottom: 1rem;
}

.stock-container p {
  margin: 0;
  padding: 0.5rem;
  border: 0.1rem solid var(--dark);
  border-radius: 0.2rem;
}

/* .product-container .flex {
  justify-content: space-between;
} */

.product-container .btn#back-to-shop {
  background-color: var(--dark) !important;
}

.out-of-stock-container {
  width: 100%;
}

/* .out-of-stock-container>div:nth-of-type(1) {
  width: 65%;
} */

.out-of-stock-container>div:nth-of-type(1) i {
  color: var(--main);
}

.out-of-stock-container .cart-summary-card {
  width: 100%;
  margin: 0;
}

.cart-widget.content {
  padding: 0;
}

.cart-widget {
  position: fixed;
  /* z-index: 0; */
  top: 0;
  right: 0;
  padding: 1rem;
  opacity: 0;
  height: 100%;
  overflow-y: auto;
  width: 0;
  margin: 0;
  background-color: var(--light);
  filter: var(--shadow);
  transition: var(--transition);
}

.cart-widget .cart-widget-card {
  margin-bottom: 1rem;
}

.cart-widget .cart-image {
  width: 100%;
}

.cart-widget .cart-buttons-container {
  justify-content: center !important;
}

.show-cart {
  z-index: 100000;
  opacity: 1;
  width: 20%;
}

.show-cart.content {
  padding: 2rem;
}

.cart-widget>.flex {
  justify-content: space-between;
}

.cart-widget>.flex i {
  cursor: pointer;
  font-size: 1.5rem;
}

.cart-widget-summary {
  text-align: center;
  margin-top: 1rem;
}

/* competion page */
.completion-container,
.checkout-form-main,
.cart-main {
  min-height: 100vh;
}

.completion-container section {
  text-align: center;
}

.completion-container strong {
  color: var(--main);
}

.completion-cart-container {
  flex-direction: column;
  justify-content: center;
  gap: 1rem;
  margin-top: 2rem;
}

.completion-cart-container .cart-item {
  gap: 2rem;
}

/* contact form */
.contact-form input,
.contact-form textarea,
.shipping-form input,
.shipping-form textarea,
.shipping-form select {
  font-family: Arial, Helvetica, sans-serif;
  background-color: white;
  width: 100%;
  padding: 0.5rem;
  border-radius: 0.2rem;
  font-size: 1rem;
  margin-bottom: 1rem;
  border: 1px solid #efefef;
  box-shadow: 0px 0px 5px var(--grey);
}

.shipping-form select {
  color: var(--dark);
}

.shipping-form input::placeholder {
  text-transform: capitalize;
}

.contact-form input:focus,
.contact-form textarea:focus,
.shipping-form input:focus,
.shipping-form textarea:focus {
  box-shadow: 0px 0px 10px var(--main);
  outline: none;
}

.contact-form div.flex,
.shipping-form div.flex {
  gap: 1rem;
}

.contact-form textarea,
.shipping-form textarea {
  resize: none;
}

.contact-form #submitBtn {
  width: 100%;
  font-size: 1rem;
}

.contact-container {
  min-height: 100vh;
}

.contact-container>div.flex {
  gap: 3rem;
  align-items: baseline;
}

.contact-container>div.flex>div {
  width: 50%;
}

.contact-info p.flex {
  gap: 1rem;
}

.contact-info strong {
  color: var(--main);
}

.contact-map img {
  width: 55%;
}

.contact-form {
  filter: var(--shadow);
  background-color: var(--light);
  padding: 1rem;
  border-radius: var(--radius);
}

/* shipping form styling */
/* .shipping-form {
  width: 60%;
} */

.shipping-form {
  width: 100%;
}

.shipping-form .button {
  width: 100%;
  font-size: 1rem;
}

.shipping-options-container {
  margin-top: 1rem;
}

.shipping-options {
  flex-direction: column;
}

.shipping-options-card {
  cursor: pointer;
  text-align: left;
  align-items: baseline;
  justify-content: space-between;
  color: var(--dark);
  width: 100%;
  font-size: 1rem;
  border: none;
  border: 0.1rem solid var(--dark);
  background-color: var(--light);
  transition: var(--transition);
}

.shipping-options-card:nth-of-type(2) {
  border-top: none;
}

.shipping-options-card input[type='radio'] {
  accent-color: var(--main);
  width: 18px;
  height: 18px;
}

.radio-container {
  align-self: center;
}

.shipping-options-card input[type='radio']:checked::after {
  background-color: var(--main);

}

.shipping-options-card>div.flex {
  align-items: baseline;
  gap: 1rem;
}

.courier {
  margin-bottom: 0.5rem;
}

.delivery {
  margin-top: 0;
}

/* payment form */
.checkout-form-main .content {
  margin-top: 0.5rem;
}

.payment-form {
  width: 60%;
  padding: 1rem;
  border-radius: var(--radius);
  background-color: var(--light);
  filter: var(--shadow);
}

.payment-form input[type='checkbox'] {
  width: max-content;
  box-shadow: none;
  margin: 0;
}

.checkout-form-main .content>div.flex {
  justify-content: space-between;
  gap: 5rem;
}

.checkout-cart-container {
  position: relative;
  width: 40%;
  padding: 1rem;
  border-radius: var(--radius);
  background-color: var(--light);
  filter: var(--shadow);
}

.checkout-cart-total {
  position: absolute;
  bottom: 0;
  right: 0;
  padding-right: 2rem;
}

.checkout-cart-total strong {
  color: var(--main);
}

.card-element-container {
  padding: 0.5rem;
  box-shadow: 0px 0px 5px var(--grey);
  background-color: var(--light);
}

.card-element-container:focus {
  box-shadow: 0px 0px 5px var(--main);
}

/* widgets */
.contact-widget .flex {
  justify-content: space-between;
  width: 100%;
}

.contact-widget div {
  width: 50%;
}

.contact-widget div:nth-of-type(2) {
  text-align: center;
}

/* footer styling */
.footer-container {
  background-color: var(--dark);
  padding: 1rem;
  color: var(--light);
}

.footer-container .flex {
  justify-content: space-around;
  align-items: baseline;
}

.footer-container .flex ul {
  margin: 0;
}

.footer-container .flex ul li {
  cursor: pointer;
  padding: 0.2rem 0;
  transition: var(--transition);
}

.footer-container .flex ul li i {
  transition: var(--transition);
  opacity: 0;
}

.footer-container .flex ul li a {
  color: var(--light);
  display: block;
}

.footer-container .content {
  margin-top: 0;
}

.footer-container h2 {
  color: var(--light);
}

.copyright-container {
  justify-content: space-around;
  background-color: var(--main);
  color: var(--light);
}

.footer-container img {
  text-align: center;
  display: block;
  width: 13vw;
  height: 5vw;
  margin: 2rem auto;
}

.copyright-container a {
  font-weight: 600;
  display: block;
  color: var(--light);
  transition: var(--transition);
}

.copyright-container a:hover {
  transform: scale(1.1);
}

/* mobile stying */
.mobile-nav,
.mobile-button,
.mobile-button-close,
.mobile-categories-container {
  display: none;
}

.mobile-nav {
  position: fixed;
  top: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.8);
  width: 50%;
  height: 100%;
  z-index: 10000;
}

.mobile-nav ul {
  flex-direction: column;
  justify-content: space-evenly;
  margin: 0;
  padding: 0;
  height: 90%;
}

.mobile-nav ul li {
  list-style: none;
}

.mobile-nav ul li a {
  text-decoration: none;
  color: var(--light);
  font-size: 2rem;
  font-weight: 600;
  text-transform: uppercase;
}

.mobile-nav#categories ul li a {
  font-size: 1rem;
}

.mobile-button-close {
  border: none;
  outline: none;
  color: var(--dark);
  font-size: 2rem;
  border-bottom-right-radius: 50%;
}

.mobile-cart-button button i {
  font-size: 1.8rem;
}

/* mobile styles */
/* @media screen and (min-width: 1400px) {
  .product-container .product-image {
    width: 30%;
  }
} */

@media screen and (max-width: 1200px) {
  .card-grid-container {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media screen and (max-width: 1020px) {
  .show-cart {
    width: 50%;
  }
}

@media screen and (max-width: 905px) {
  .card-grid-container {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (max-width: 768px) {

  .logo-container img {
    width: 150px;
    height: 50px;
  }

  .main-nav,
  .main-categories {
    display: none;
    height: 0;
  }

  .mobile-button {
    display: block;
  }

  .mobile-button i {
    font-size: 1.8rem;
  }

  #shop>div.flex {
    padding: 3rem 2rem;
  }


  section.content.flex {
    flex-direction: column-reverse;
    padding-top: 0;
    margin-top: 0;
    gap: 2rem;
  }

  .welcome-content {
    width: 80%;
  }

  .mobile-categories-container {
    display: flex;
    justify-content: space-evenly;
  }

  .mobile-categories-container div.flex {
    gap: 0.5rem;
  }

  .main-search {
    display: none;
  }

  .categories-array {
    display: none;
  }

  .search-bar input {
    font-size: 16px;
  }

  .masthead-buttons {
    font-size: 1.3rem;
  }

  .fa-solid.fa-magnifying-glass.search-icon {
    display: none;
  }

  .search-bar {
    margin: 0;
  }

  .contact-container div.flex {
    flex-direction: column;
  }

  .contact-container>div.flex>div {
    width: 100%;
  }

  .checkout-form {
    flex-direction: column;
  }

  .payment-form {
    width: 80%;
  }

  .contact-form input,
  .contact-form textarea,
  .contact-form .button,
  .shipping-form input,
  .shipping-form textarea,
  .shipping-form select,
  .shipping-form .button,
  .cart-summary {
    font-size: 16px;
  }

  .cart-summary-card,
  .checkout-cart-container,
  .payment-form {
    filter: none;
  }

  .payment-form {
    margin-top: 1rem;
  }

  .checkout-cart-container {
    width: 80%;
  }

  .checkout-cart-container .cart-item {
    width: 100%;
  }

  .cart-container {
    flex-direction: column;
  }

  .cart-items {
    width: 100%;
  }

  .cart-item {
    width: 80%;
  }

  .cart-image {
    width: 15vw;
    min-height: 15vw;
  }

  .cart-summary-card {
    width: 80%;
  }

  .cart-summary {
    width: 100%;
  }

  section.content.about-content.flex {
    flex-direction: column;
  }

  .product-container {
    flex-direction: column;
    align-items: baseline;
    min-height: 0;
  }

  .product-container .product-image {
    width: 60%;
  }

  .product-content {
    width: 100%;
  }
}

@media screen and (max-width: 670px) {
  .mobile-categories-container p {
    display: none;
  }

  .card-grid-container {
    grid-template-columns: repeat(1, 1fr);
    padding: 3rem 4rem;
  }

}

@media screen and (max-width: 565px) {
  .mobile-nav {
    width: 100%;
    background-color: rgba(0, 0, 0, 0.9)
  }

  .cart-item {
    width: 100%;
  }

  .product-container .product-image {
    width: 100%;
  }

  .masthead-container img,
  .masthead-container .backdrop {
    height: 115%;
  }

  .content#shop {
    padding: 1rem;
  }

  .content#shop .categories-title {
    padding: 1rem 3rem;
  }
}

@media screen and (max-width: 450px) {

  .footer-container div.flex {
    flex-direction: column;
  }

  .footer-container div.flex ul {
    padding: 0;
    display: flex;
    list-style: none;
    justify-content: space-evenly;
    gap: 1rem;
  }

  .cart-summary-card {
    width: 95%;
  }

  .show-cart {
    width: 90%;
  }

  .cart-image {
    width: 25vw;
    min-height: 25vw;
  }

  .welcome-img {
    width: 100%;
  }

  .welcome-img img {
    width: 100%;
  }

  .welcome-content {
    width: 100%;
  }

  .contact-map img {
    width: 100%;
  }

  .out-of-stock-container>div:nth-of-type(1) {
    width: 100%;
  }

  .payment-form {
    width: 95%;
  }

  .checkout-cart-container {
    width: 95%;
  }

  .checkout-form-main .content {
    padding: 1rem;
  }

}

@media screen and (max-width: 400px) {
  .masthead-container h1 {
    font-size: 3rem;
  }

  .copyright-container {
    justify-content: space-between;
    padding: 0 0.5rem;
  }

}

@media screen and (max-width: 375px) {
  .footer-container div.flex ul {
    flex-direction: column;
  }
}